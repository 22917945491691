<template>
    <div id="appt" class="appointment-component">
        <!-- FORM VIEW -->
        <div
            v-show="!showPreview"
            class="appointment__form dashboard-grid"
        >
            <div ref="wrapperHeight" class="col-span-1">
                <doctor-profile-component
                    :doctor-id="doctorId"
                    :show-appointment-btn="false"
                    :show-close-btn="false"
                />
            </div>
            <div class="col-span-1">
                <request-appointment-component
                    @on-show-preview="onShowPreview($event)"
                />
            </div>
        </div>
        <!-- PREVIEW FORM -->
        <div v-show="showPreview" class="appointment__preview">
            <div class="wrapper">
                <h2 class="mb-6">Preview appointment request</h2>
                <profile-info-card-component v-bind="docProperties" />

                <div class="border-t border-t-gray-200" />

                <appointment-card-component
                    show-date
                    :data="appointment"
                    :show-invited="
                        _.has(appointment, 'invited') && appointment.invited
                    "
                    show-brand
                    show-note
                    show-credit-to-use
                    no-shadow-effect
                    :show-name="false"
                    :show-remarks="false"
                    :show-remarks-fields="false"
                    :show-medrep-name="false"
                    :description="description"
                    preview-appointment
                    card-class="appointment-card"
                />

                <!-- ACTION BUTTONS -->
                <div class="action-btn flex justify-end pb-6">
                    <button-component
                        type="white-btn"
                        label="Edit"
                        class="mr-3"
                        @click="showPreview = !showPreview"
                    >
                        <template v-slot:prepend>
                            <PencilAltIcon
                                class="text-gray-500"
                                aria-hidden="true"
                            />
                        </template>
                    </button-component>
                    <button-component
                        v-bind="submitButtonProps"
                        @click="onSubmitForm"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DoctorProfileComponent from "@MedrepComponents/doctors/components/doctor-info/doctor-profile-component.vue";
import RequestAppointmentComponent from "./request-appointment-component.vue";
import { computed, ref, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { PencilAltIcon } from "@heroicons/vue/solid";
import {
    ProfileInfoCardComponent,
    AppointmentCardComponent,
} from "@CommonComponents";

export default {
    name: "AppointmentComponent",
    components: {
        DoctorProfileComponent,
        RequestAppointmentComponent,
        ProfileInfoCardComponent,
        AppointmentCardComponent,
        PencilAltIcon,
    },
    setup() {
        const store = useStore();
        const route = useRoute();
        const router = useRouter();

        const doctorId = computed(() => {
            return route.query.id;
        });

        // START OF FETCHING DOCTOR DATA FOR THE PREVIEW
        const doctor = computed(
            () => store.getters["CommonDoctor/doctorProfile"]
        );
        const docProperties = reactive({
            profile: doctor,
            haveHoverEffect: false,
        });
        // END OF FETCHING DOCTOR DATA

        // START OF SHOW PREVIEW APPOINTMENT
        const appointment = ref({});
        const showPreview = ref(false);
        const formData = ref({});
        const description = ref("");

        const onShowPreview = (data) => {
            const { form } = data;
            formData.value = form;
            appointment.value = {
                status: "pending",
                appointment_date: !_.isEmpty(form.appointment_date) ? form.appointment_date.longdate : "",
                appointment_time: !_.isEmpty(form.appointment_time) ? form.appointment_time.time.label : "",
                brands: formatAppointmentData(form.brands, "name"),
                credit_use: form.credits.credit_use,
                message: form.message,
            };
            description.value = !_.isEmpty(form.appointment_date) ? "The doctor may reschedule the appointment date and time" : "The doctor will schedule the appointment date and time"
            showPreview.value = data.show;
        };
        const formatAppointmentData = (brands, key) => {
            const item = _.map(brands, (i) => i[key]);
            return item;
        };
        // END OF SHOW PREVIEW

        // START OF CREDIT BALANCE
        const getUserCredits = async () => {
            await store.dispatch("CommonCredit/getUserCredits");
        }
        const hasNoBalance = computed(
            () => store.getters["CommonCredit/hasNoBalance"]
        );
        const isInsufficientBalance = computed(
            () => store.getters["CommonCredit/isInsufficientBalance"]
        );
        // END OF CREDIT BALANCE

        // START OF SUBMIT BUTTON PROPERTIES
        const disableButton = ref(false)
        const requestLoading = ref(false)
        const submitButtonProps = reactive({
            type: "primary-btn",
            label: "Submit",
            disabled: disableButton,
            loading: requestLoading,
        })
        // END OF SUBMIT BUTTON PROPERTIES

        // START OF SUBMITTING FORM
        const onSubmitForm = async () => {
            requestLoading.value = true;
            await getUserCredits();
            if (hasNoBalance.value || (formData.value.credits.count === 2 && isInsufficientBalance.value)) {
                disableButton.value = true
                return
            }
            const payload = {
                appointment_date: !_.isEmpty(formData.value.appointment_date) ? formData.value.appointment_date.shortdate : null,
                doctor_id: doctorId.value,
                doctor_schedule_id: !_.isEmpty(formData.value.appointment_time) ? formData.value.appointment_time.time.id : null,
                credits: _.toString(formData.value.credits.count),
                brands: formatAppointmentData(formData.value.brands, "id"),
                message: formData.value.message,
            };
            const response = await store.dispatch('CommonAppointment/requestAppointment', payload)

            if (response.success) {
                // TO UPDATE CREDIT BALANCE
                getUserCredits();

                // TO SEND FEEDBACK DETAILS WHEN SHOWING THE FLOATING FEEDBACK
                const feedback = {
                    title: response.message[0],
                    message: response.message[1],
                    buttonLabel: response.message[2],
                }
                store.commit('CommonUtilities/setFeedbackObject', feedback);
                router.push({ path: '/medrep/dashboard' });
            }
            requestLoading.value = true;
        };
        // END OF SUBMITTING FORM

        return {
            doctorId,
            showPreview,
            onSubmitForm,
            docProperties,
            appointment,
            onShowPreview,
            description,
            formData,
            submitButtonProps,
        };
    },
};
</script>

<style lang="scss">
.appointment-component {
    // @apply px-0 py-0;
    transform: translateY(-54px);
    @apply h-full;
    h2 {
        @apply text-lg leading-6 font-medium text-gray-900;
    }
    .appointment {
        &__form {
            .doctor-profile-component {
                @apply -mt-6 -ml-6;
                @apply bg-white;
                .doctor-profile-card-component {
                    .slideover-header-component {
                        .slideover-header-component {
                        }
                    }
                }
            }
        }
        &__preview {
            @apply h-full flex justify-center items-center bg-white;
            .wrapper {
                @apply space-y-4;
                width: 395px;
                .profile-info-card-component {
                    .profile-card {
                        @apply p-0 shadow-none #{!important};
                    }
                }
                .appointment-card-component {
                    @apply space-y-4;
                    .appointment-settings-card-component,
                    .appointment-body-card-component {
                        @apply p-0 #{!important};
                    }
                    // .appointment-body-card-component {
                    //     .credit-to-use-card-component {
                    //         .credit-status-info, svg {
                    //             @apply hidden;
                    //         }
                    //     }
                    // }
                }
                .action-btn {
                    button {
                        min-width: 82px;
                    }
                }
            }
        }
    }
}
</style>